import React from 'react';
import Spinner from 'react-spinners/MoonLoader';

const PageLoader: React.FC = () => {
  return (
    <div className="main h-screen flex justify-center items-center">
      <Spinner size={60} color="#0b98c5" />
    </div>
  );
};

export default PageLoader;
