import React, { lazy, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';

import { useTypedStoreState } from './store/useTypedStoreState.ts'; 
import SuspenseWithChunkError from './components/suspenseWithChunkError';
import PageLoader from "./components/PageLoader.tsx";
import { ApiUtils } from "./utils/ApiUtils";

interface Emitter {
  on: <T>(event: string, fn: (payload: T) => void) => void;
  once: <T>(event: string, fn: (payload: T) => void) => void;
  off: <T>(event: string, fn: (payload: T) => void) => void;
  emit: <T>(event: string, payload: T) => void;
}

interface AppProps {
  emitter: Emitter;
}

// Lazy loaded components
const Login = lazy(() => import('./views/Auth/Login'));
const Signup = lazy(() => import('./views/Auth/Signup'));
const Signout = lazy(() => import('./views/Auth/SignOut'));
const RequestResetPassword = lazy(() => import('./views/Auth/RequestResetPassword'));
const ResetPassword = lazy(() => import('./views/Auth/ResetPassword'));
const Verify = lazy(() => import('./views/Auth/Verify'));
const AccountVerification = lazy(() => import('./views/Auth/AccountVerification'));
const Dashv3 = lazy(() => import('./views/Dashboard/Dashv3.jsx'));
const ProjectList = lazy(() => import('./views/Diagram/ProjectList.jsx'));
const ProjectDetail = lazy(() => import('./views/Diagram/ProjectDetail'));
const CodeApp = lazy(() => import('./views/CodeApp'));

const App: React.FC<AppProps> = ({ emitter }) => {
  const addUser = useStoreActions((actions: any) => actions.addUser); 

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(ApiUtils.uriUserMe(), { method: "GET" });
        if (!response.ok) {
          throw new Error('Error fetching user');
        }
        const result = await response.json();
        addUser({ isAuthenticated: true, isLoading: false, details: result });
      } catch (error) {
        let errorMessage = 'An error occurred';
        if (error.message.includes('500')) {
          errorMessage = 'Server error occurred. Please try again later.';
        } else if (error.message.includes('400')) {
          errorMessage = 'Client error occurred. Please check your request and try again.';
        }
        addUser({ isAuthenticated: false, isLoading: false, error: errorMessage });
      }
    };

    fetchUser();
  }, [addUser]);

  // Refactor ProtectedRoute to use typed store state
  const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isLoading, isAuthenticated } = useTypedStoreState((state) => state.user); // Use typed store state

    if (isLoading) {
      return <PageLoader />;
    }

    if (!isAuthenticated) {
      return <Navigate to="/app/login" />;
    }

    return <>{children}</>;
  };

  return (
    <BrowserRouter>
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Routes>
          <Route path="/app/login" element={<Login />} />
          <Route path="/app/signup" element={<Signup />} />
          <Route path="/app/signout" element={<Signout />} />
          <Route path="/app/verify-account" element={<AccountVerification />} />
          <Route path="/app/verify" element={<Verify />} />
          <Route path="/app/reset-password-request" element={<RequestResetPassword />} />
          <Route path="/app/reset-password" element={<ResetPassword />} />
          <Route path="/app/dash" element={
            <ProtectedRoute>
              <Dashv3 />
            </ProtectedRoute>
          } />
          <Route path="/app/projects" element={
            <ProtectedRoute>
              <ProjectList />
            </ProtectedRoute>
          } />
          <Route path="/app/projects/:projectId" element={
            <ProtectedRoute>
              <ProjectDetail />
            </ProtectedRoute>
          } />
          <Route path="/app/code/:diagramId/*" element={
            <ProtectedRoute>
              <CodeApp emitter={emitter} />
            </ProtectedRoute>
          } />
        </Routes>
      </SuspenseWithChunkError>
    </BrowserRouter>
  );
};

export default App;
