import { createStore, action, Action } from 'easy-peasy';

// Define the type for the user state
interface User {
  isAuthenticated: boolean;
  isLoading: boolean;
  details: Record<string, never>; // Adjust this according to the actual shape of user details
}

// Define the type for the store model
export type StoreModel = {
  user: User;
  addUser: Action<StoreModel, User>;
}

// Create the store with type definitions
export const store = createStore<StoreModel>({
  user: {
    isAuthenticated: false,
    isLoading: true,
    details: {},
  },
  addUser: action((state, payload) => {
    state.user = payload;
  }),
});
