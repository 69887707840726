window.global ||= window;

import { createRoot } from 'react-dom/client';
import { StoreProvider } from 'easy-peasy';
import { Amplify } from "aws-amplify";
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from "@sentry/react";

import appSyncConfig from "./graphql/aws-appsync";
import { store } from './store/store';
import emitter from './emitter.js';

Amplify.configure(appSyncConfig);

import App from './App';
import './index.css';

Sentry.init({
  dsn: "https://63afc414a75a62e01daa859ec883a54c@o77594.ingest.us.sentry.io/4508267427659776",
  integrations: [],
});

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <StoreProvider store={store}>
      <HelmetProvider>
        <App emitter={emitter} />
      </HelmetProvider>
    </StoreProvider>
  );
} else {
  console.error("Root container not found");
}
