import isBlank from 'underscore.string/isBlank';

const BASE_API_URL = '/api';

export class ApiUtils {

  /**
   * @return {string}
   */
  static uriDjangoLogin() {
    return `${BASE_API_URL}/users/login`;
  }

  /**
   * @return {string}
   */
  static uriDjangoLogout() {
    return `${BASE_API_URL}/users/logout/`;
  }

  /**
   * @return {string}
   */
  static uriApiCourses(searchString, page) {
    const pageParam = page ? `page=${page}` : '';

    const searchParam = isBlank(searchString)
      ? ''
      : `search=${encodeURIComponent(searchString)}`;

    return searchString || pageParam
      ? `${BASE_API_URL}/courses/?${searchParam}&${pageParam}`
      : `${BASE_API_URL}/courses/`;
  }

  /**
   * @param {string} slug
   * @return {string}
   */
  static uriApiCourseDetail(slug) {
    return `${BASE_API_URL}/courses/${slug}/`;
  }

  /**
   * @param {string} slug
   * @return {string}
   */
  static uriApiLessonDetail(slug) {
    return `${BASE_API_URL}/lesson/${slug}/`;
  }

  /**
   * @return {string}
   */
  static uriUserMe() {
    return `${BASE_API_URL}/users/me`;
  }

  /**
   * @param {string} lessonSlug
   * @param {string} lessonId
   * @return {string}
   */
  static uriMarkLessonCompleted(lessonSlug) {
    return `${BASE_API_URL}/lesson/${lessonSlug}/`;
  }

  /**
   * @return {string}
   */
  static uriUserRegistration() {
    return `${BASE_API_URL}/users/register`;
  }

  /**
   * @return {string}
   */
  static uriVerifyToken(uid, token) {
    return `${BASE_API_URL}/users/verify/${uid}/${token}/`;
  }

  static uriUserStream(contentId, userId) {
    return `${BASE_API_URL}/activity/feed/${contentId}/${userId}/json/`
  }

}
