import { Auth } from 'aws-amplify';

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
});

const appSyncConfig = {
  Auth: {
    region: "us-west-2",
    userPoolId: "us-west-2_WPyErWsKq",
    userPoolWebClientId: "7n7pptago0hvi6d5nata71oli4",
    type: "AMAZON_COGNITO_USER_POOLS"
  },
  graphql_headers: getIdToken,
  aws_appsync_graphqlEndpoint: "https://vlsay34z3jczhkaq5b5c2pjzyy.appsync-api.us-west-2.amazonaws.com/graphql",
  aws_appsync_region: "us-west-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",

};

export default appSyncConfig;
